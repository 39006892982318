const mobileCarouselData = [
    {
        'rus': 'ПН',
        'eng': 'monday'
    },
    {
        'rus': 'ВТ',
        'eng': 'tuesday'
    },
    {
        'rus': 'СР',
        'eng': 'wednesday'
    },
    {
        'rus': 'ЧТ',
        'eng': 'thursday'
    },
    {
        'rus': 'ПТ',
        'eng': 'friday'
    },
    {
        'rus': 'СБ',
        'eng': 'saturday'
    }
]

const desktopCarouselData = [
    {
        'rus': 'Понедельник',
        'eng': 'monday'
    },
    {
        'rus': 'Вторник',
        'eng': 'tuesday'
    },
    {
        'rus': 'Среда',
        'eng': 'wednesday'
    },
    {
        'rus': 'Четверг',
        'eng': 'thursday'
    },
    {
        'rus': 'Пятница',
        'eng': 'friday'
    },
    {
        'rus': 'Суббота',
        'eng': 'saturday'
    },
    {
        'rus': 'Вся неделя',
        'eng': 'everyone'
    }
]

export {mobileCarouselData, desktopCarouselData}