import React, {Component} from "react";
import {connect} from "react-redux";
import Consts from "../../../../helpers/consts";
import "./style.css";

class NotificationLocal extends Component {
    isShown() {
        const currentTime = this.props.date.getTime();
        const semesterStart = this.props.semesterStart;
        const twoWeeks = 1209600000;

        return (currentTime < semesterStart + twoWeeks) ? true : false;
    }

    render() {
        const { windowWidth } = this.props;
        const isMobile = windowWidth < Consts.DESKTOP_MIN_WIDTH;
        
        const text = 'В течение первых двух недель в расписании возможны изменения — следите за обновлениями';

        if (this.isShown()) {
            return (
                <div className='notification'>
                    <p className={`notification__text ${isMobile ? 'text-regular--small' : 'text-regular--medium'}`}>
                        {text}
                    </p>
                </div>
            );
        } else {
            return <></>;
        }        
    }
}

const mapStateToProps = (state) => {
    return {
        date: state.appTimer.date,
        semesterStart: state.raspConfig.data.semester_start,
        windowWidth: state.windowSizes.width
    };
};

export default connect(mapStateToProps)(NotificationLocal);
