import React, {Component} from 'react';
import './style.css';

class Loader extends Component {
    render() {

        return (
            <div className="loader" id="loader-1"></div>
        );
    }
}

export default Loader;